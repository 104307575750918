<script setup lang="ts">
import type { TransferListOptionsItem } from '@reports/components/TransferList/types'

interface Props {
  options: TransferListOptionsItem[]
}

const props = withDefaults(defineProps<Props>(), {
  options: () => [],
})

defineEmits(['select'])

const sortedOptions = computed(() =>
  [...props.options].sort(
    (
      a: { label: string; value: string },
      b: { label: string; value: string },
    ) => {
      return a.label.localeCompare(b.label)
    },
  ),
)
</script>

<template>
  <ReportsTransferListBaseList :options="sortedOptions" variant="secondary">
    <template #item="{ option }">
      <ReportsTransferListItem
        :option="option"
        variant="secondary"
        class="overflow-y-auto scroll-smooth group-hover/list:cursor-pointer"
        :action="{ icon: 'plus', tooltip: 'Add to Selected fields' }"
        action-class="text-primary"
        @click-row="$emit('select', option)"
        @click-button="$emit('select', option)"
      />
    </template>
    <template #empty> All fields were selected </template>
  </ReportsTransferListBaseList>
</template>
